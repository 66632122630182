var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.overviewData, function(item, index) {
      return _c(
        "div",
        {
          key: index,
          staticClass:
            "\n      grid\n      grid-cols-[1fr_max-content]\n      border-t\n      last:border-b\n      border-color2\n      gap-4\n    "
        },
        [
          item.data
            ? _c("div", { staticClass: "pt-4 pb-12" }, [
                _c("div", { staticClass: "roboto-bold-f2 mb-8" }, [
                  _vm._v(_vm._s(_vm.t(item.type)))
                ]),
                _vm._v(" "),
                item.type == "tuer"
                  ? _c(
                      "div",
                      _vm._l(item.data, function(tuerItem, tuerIndex) {
                        return _c("div", { key: index + "-" + tuerIndex }, [
                          _c(
                            "div",
                            { staticClass: "roboto-f3 text-color3 pb-2" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.t(tuerIndex)) +
                                  ": " +
                                  _vm._s(tuerItem.label) +
                                  "\n          "
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  : item.type == "briefkasten"
                  ? _c("div", [
                      _c("div", { staticClass: "roboto-bold-f3" }, [
                        _vm._v(_vm._s(item.data.hauptbild.label))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "roboto-f3 mt-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.t("Farbe")) +
                            ": " +
                            _vm._s(item.data.farbAuswahl.label) +
                            "\n        "
                        )
                      ])
                    ])
                  : item.type == "sonnerie"
                  ? _c("div", [
                      _c("div", { staticClass: "roboto-bold-f3" }, [
                        _vm._v(_vm._s(item.data.label))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "roboto-f3 mt-2" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item.data.infos) +
                            "\n        "
                        )
                      ])
                    ])
                  : item.type == "personalien"
                  ? _c("div", [
                      _c("div", { staticClass: "roboto-bold-f3" }, [
                        _vm._v(_vm._s(item.data.firma.value))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "roboto-f3 my-2" }, [
                        _c("div", [
                          _vm._v(
                            _vm._s(item.data.name.value) +
                              " " +
                              _vm._s(item.data.nachname.value)
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.data.adresse.value))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.data.plzOrt.value))])
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "roboto-f3" }, [
                        _c("div", [_vm._v(_vm._s(item.data.email.value))]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(item.data.telefon.value))])
                      ])
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "self-center w-10 h-10 rounded-full flex items-center bg-color5",
              attrs: { href: item.url + "?edit=true" }
            },
            [_c("i", { staticClass: "fa-solid fa-pencil mx-auto" })]
          )
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }