var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "centerbox" }, [
    _c("div", { staticClass: "modular-image-grid m:block" }, [
      _c(
        "div",
        {
          staticClass:
            "\n        relative\n        col-start-2 col-span-1\n        row-start-2 row-span-1\n        min-w-0\n        overflow-hidden\n      "
        },
        [
          _c("img", {
            staticClass:
              "relative h-auto w-[450px] m:h-[200px] m:w-auto max-w-full mx-auto",
            attrs: { src: _vm.baseImage, alt: "" }
          }),
          _vm._v(" "),
          _c("div", {
            staticClass:
              "absolute top-0 left-0 right-0 bottom-0 mix-blend-multiply",
            style: {
              backgroundColor: _vm.konf.mailboxData.farbAuswahl
                ? _vm.konf.mailboxData.farbAuswahl.color
                : ""
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }