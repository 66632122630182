
































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { FormularService } from '../services/formular.service';
import { KonfiguratorService } from '../services/konfigurator.service';

const SERVICES = VServices({
  konf: KonfiguratorService,
  form: FormularService,
});

export default Vue.extend({
  name: 'userForm',
  props: {},
  components: {},
  data() {
    return {
      persFormData: {
        Personalien: [
          { label: 'Ihr Name*', handle: 'name' },
          { label: 'Ihr Familienname*', handle: 'nachname' },
        ],
        Firma: [
          { label: 'Firma*', handle: 'firma' },
          { label: 'Adresse*', handle: 'adresse' },
          { label: 'PLZ Ort*', handle: 'plzOrt' },
        ],
        Kontakt: [
          { label: 'E-Mail*', handle: 'email' },
          { label: 'Telefon*', handle: 'telefon' },
        ],
      },
      formular: SERVICES.form,
    };
  },
  mounted() {
    SERVICES.form.getStoredForm();
  },
  methods: {},
});
