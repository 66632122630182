import { VBaseService, VServices } from '@libTs/vue-base';
import { KonfiguratorService } from './konfigurator.service';

const SERVICES = VServices( { konf: KonfiguratorService } );

export class CalcService extends VBaseService {
  public price = 0;

  public calcPrice( basePrice: number ) {
    SERVICES.konf.getStoredData();
    SERVICES.konf.getStoredMailboxData();
    SERVICES.konf.getStoredSonnerie();

    this.price = Number( basePrice );

    for ( const type in SERVICES.konf.data ) {
      let multiplier = 0;
      if ( Object.prototype.hasOwnProperty.call( SERVICES.konf.data, type ) ) {
        const element = SERVICES.konf.data[ type ];
        multiplier = Number( element.aufpreisMultiplikator );

        if ( element.aufpreis && !isNaN( element.aufpreis ) ) {
          this.price += Number( element.aufpreis );
        }

        if ( type === 'seitenteil' ) {
          this.price +=
            Number( SERVICES.konf.data.seitenteilbreite.standardPreis ) *
            multiplier;
        }

        if ( type === 'seitenteilbreite' ) {
          if ( element.zusatzAufpreise.length ) {
            const zusatzAufpreise = element.zusatzAufpreise;

            for ( let i = 0; i < zusatzAufpreise.length; i++ ) {
              const aufpreisElement = zusatzAufpreise[ i ];
              const condition: string = aufpreisElement.aufpreisKondition;
              const conditionArray = condition.split( '%' );

              let lastEl: any = undefined;

              for ( let i = 0; i < conditionArray.length - 1; i++ ) {
                const conditionField = conditionArray[ i ];

                if ( lastEl === undefined ) {
                  lastEl = SERVICES.konf.data[ conditionField ];
                  continue;
                }

                lastEl = lastEl[ conditionField ];
              }

              const conditionValue =
                conditionArray[ conditionArray.length - 1 ];

              if ( conditionValue == lastEl ) {
                multiplier =
                  SERVICES.konf.data.seitenteil.aufpreisMultiplikator;
                this.price += multiplier * Number( aufpreisElement.aufpreis );
              }
            }
          }
        }
      }
    }

    for ( const type in SERVICES.konf.mailboxData ) {
      if (
        Object.prototype.hasOwnProperty.call( SERVICES.konf.mailboxData, type )
      ) {
        const element = SERVICES.konf.mailboxData[ type ];

        if ( element.aufpreis && !isNaN( element.aufpreis ) ) {
          this.price += Number( element.aufpreis );
        }
      }
    }

    if ( SERVICES.konf.sonnerieData && SERVICES.konf.sonnerieData.aufpreis ) {
      this.price += Number( SERVICES.konf.sonnerieData.aufpreis );
    }

    this._storePrice( basePrice );
  }

  public getBasePrice() {
    const priceDataString = window.localStorage.getItem( 'conf-price' );

    if ( priceDataString ) {
      const priceData = JSON.parse( priceDataString );
      return priceData.basePrice;
    }

    return 0;
  }

  public getMoneyFormat( price: number ) {
    return new Intl.NumberFormat( 'de-CH', {
      style: 'currency',
      currency: 'CHF',
    } ).format( price );
  }

  private _storePrice( basePrice: number ) {
    window.localStorage.setItem( 'conf-price', JSON.stringify( { price: this.price, basePrice } ) );
  }
}