import { SITEURL } from '@libTs/cms.globals';
import { VServices } from '@libTs/vue-base';
import { VDirective } from '@libTs/vue-base/v.directive';
import { OverlaysService } from '../services/overlays.service';
import gsap from "gsap";

const SERVICES = VServices( { overlays: OverlaysService } );

/**
 * v-nav
 */
export const NavDirective: VDirective = [
  'nav', // translates to attribute "v-nav" in HTML
  {
    // called only once, when the directive is first bound to the element.
    bind(): void {
      $( () => {
        const navEl = "#start";
        SERVICES.overlays.activeNavEl = navEl;

        if ( $( navEl ).length ) {
          gsap.to( window, {
            duration: 1,
            scrollTo: { y: navEl, offsetY: 40 },
          } );
        }
      } );
    },
  }
];
