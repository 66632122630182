var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ml-2 relative h-max" }, [
    _c("i", {
      staticClass: "fa-solid fa-circle-info cursor-pointer",
      on: {
        click: function($event) {
          $event.stopPropagation()
          _vm.show = !_vm.show
        }
      }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticClass:
          "\n      absolute\n      bottom-8\n      -right-4\n      w-80\n      max-w-info\n      p-3\n      roboto-f4\n      bg-color5\n      shadow-md\n      rounded-md\n    ",
        on: {
          click: function($event) {
            $event.stopPropagation()
          }
        }
      },
      [
        _c(
          "div",
          {
            staticClass: "absolute px-1 top-1 right-1 cursor-pointer",
            on: {
              click: function($event) {
                $event.stopPropagation()
                _vm.show = !_vm.show
              }
            }
          },
          [_c("i", { staticClass: "fa-solid fa-xmark" })]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "\n        absolute\n        right-4\n        -bottom-1\n        -rotate-45\n        w-3\n        h-3\n        bg-color5\n        shadow-info\n      "
        }),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "\n        pointer-events-none\n        absolute\n        bottom-0\n        right-0\n        w-full\n        h-3\n        bg-color5\n        overflow-hidden\n        max-w-info\n        rounded-md\n      "
        }),
        _vm._v("\n\n    " + _vm._s(_vm.text) + "\n  ")
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }