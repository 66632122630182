import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as IEnvironment from './env';
gsap.registerPlugin( ScrollTrigger );

const ENV = IEnvironment.ENV;

const animations = {
  //true for fade in animation -> false no animation
  animationDev: true,
  animationStaging: true,

  //default value is 1 -> 1 * animationDurationMultiplier
  animationDurationMultiplier: .5,

  //0% -> top -> 100% -> bottom
  scrollerPosition: '85%',
  scrollerPositionMobile: '95%',
  scrollerPositionTop: '60%',

  //positive value -> fade in from bottom | negative value -> fade in from top
  yStartpoint: 120,
  yEndpoint: 0,

  //positive value -> fade in from right | negative value -> fade in from left
  xStartpoint: 0,
  xEndpoint: 0,

  // play none none reverse -> repeat animation | play none none none -> play animation once
  toggleActions: 'play none none reverse',

  //Modules to not animate => Array with classnames  ex. ['webkit-slideshow-container', 'webkit-grid-container']
  modulesToNotAnimate: [ '' ],

  //For Debugging 
  activateMarkers: false,
};

let animationActive = true;
let scrollerPosition = animations.scrollerPosition;

// if ( ENV.isProduction ) {
//   if ( ENV.name != 'dev' && animations.animationStaging ) {
//     animationActive = true;
//   } else {
//     animationActive = false;
//   }
// } else {
//   animationActive = animations.animationDev;
// }

if ( window.innerWidth <= 480 ) {
  scrollerPosition = animations.scrollerPositionMobile;
} else {
  scrollerPosition = animations.scrollerPosition;
}

function reloaded() {
  ScrollTrigger.refresh( true );
}

$( window ).on( 'load', reloaded );

$( () => {
  const el = $( '.angebot-banner' )[ 0 ];
  const intro = $( '.intro-container' )[ 0 ];
  const footer = $( 'footer' )[ 0 ];

  if ( !el ) {
    return;
  }

  const animation = gsap.fromTo( el,
    {
      autoAlpha: 1,
      duration: 1 * animations.animationDurationMultiplier,
      clearProps: 'all',
    },
    {
      autoAlpha: 0,
      // y: 0,
      x: 100,
      duration: 1 * animations.animationDurationMultiplier,
    } );

  const animationR = gsap.fromTo( el,
    {
      autoAlpha: 0,
      x: 100,
    },
    {
      x: 0,
      clearProps: 'all',
      autoAlpha: 1,
      duration: 1 * animations.animationDurationMultiplier,
    } );

  ScrollTrigger.create( {
    trigger: footer,
    start: `top ${scrollerPosition}`,
    animation: animation,
    markers: false,
    toggleActions: animations.toggleActions,
  } );

  ScrollTrigger.create( {
    trigger: intro,
    start: `bottom ${animations.scrollerPositionTop}`,
    animation: animationR,
    markers: false,
    toggleActions: animations.toggleActions,
  } );
} );

