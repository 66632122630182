import { render, staticRenderFns } from "./infoButton.vue?vue&type=template&id=6606b2bc&"
import script from "./infoButton.vue?vue&type=script&lang=ts&"
export * from "./infoButton.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/binaryone/hoermann-walk-in-48/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6606b2bc')) {
      api.createRecord('6606b2bc', component.options)
    } else {
      api.reload('6606b2bc', component.options)
    }
    module.hot.accept("./infoButton.vue?vue&type=template&id=6606b2bc&", function () {
      api.rerender('6606b2bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/konfigurator/ts/components/infoButton.vue"
export default component.exports