import { VDirective } from '@libTs/vue-base/v.directive';
import gsap from 'gsap';
import { DirectiveBinding } from 'vue/types/options';


export const ScrollDirective: VDirective = [
  'scroll',
  {
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      el.addEventListener( 'click', function () {
        gsap.to( window, {
          duration: .5,
          scrollTo: { y: binding.value, offsetY: 40 },
        } );
      } );
    },
  },
];
