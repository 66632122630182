
















































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { KonfiguratorService } from '../services/konfigurator.service';
const SERVICES = VServices({ konf: KonfiguratorService });

export default Vue.extend({
  name: 'modularDoor',
  props: {
    baseImage: {
      required: true,
    },
    baseSeitenteile: {
      required: true,
    },
    baseData: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      seitenteilPositioning: {
        left: 'col-start-1 col-span-1 row-start-2 row-span-1 -mr-[1px]',
        right: 'col-start-3 col-span-1 row-start-2 row-span-1 -ml-[1px]',
      },
      konf: SERVICES.konf,
    };
  },
  mounted() {
    if (this.baseData) {
      SERVICES.konf.data = JSON.parse(this.baseData);
      this.parseSeitenteile();
    }
    SERVICES.konf.getStoredData();

    if (this.baseImage) {
      SERVICES.konf.baseImage = this.baseImage;
      SERVICES.konf.storeData();
      this.$forceUpdate();
    }

    window.addEventListener('data-loaded', () => {
      this.parseSeitenteile();
    });

    window.addEventListener('seitenteil-changed', () => {
      this.$forceUpdate();
    });

    window.addEventListener('form-changed', () => {
      SERVICES.konf.konfUrl = window.location.href;
      SERVICES.konf.storeData();
    });
  },
  methods: {
    parseSeitenteile() {
      if (!SERVICES.konf.baseSeitenteile.size) {
        const seitenteile: Array<any> = JSON.parse(this.baseSeitenteile);
        const seitenteileMap = new Map();

        for (let i = 0; i < seitenteile.length; i++) {
          const element = seitenteile[i];

          for (const key in element) {
            if (Object.prototype.hasOwnProperty.call(element, key)) {
              const seitenteilLink = element[key];
              seitenteileMap.set(key, seitenteilLink);

              if (!SERVICES.konf.baseSeitenteile.has(key)) {
                SERVICES.konf.baseSeitenteile.set(key, seitenteilLink);
              }
            }
          }
        }
        SERVICES.konf.seitenteile = seitenteileMap;
      }
    },
  },
});
