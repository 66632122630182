var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "centerbox" }, [
    _c(
      "div",
      { staticClass: "modular-image-grid", attrs: { id: "my-door" } },
      [
        _vm._l(_vm.seitenteilPositioning, function(seitenteil, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "relative min-w-0 overflow-hidden",
              class: seitenteil
            },
            [
              _vm.konf.data.glasart
                ? _c("div", {
                    staticClass: "absolute top-0 left-0 right-0 bottom-0 z-0",
                    style: { backgroundColor: _vm.konf.data.glasart.color }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "mask h-[450px] m:h-[200px] w-auto",
                  class: index == "right" ? "-scale-x-100" : "",
                  style: {
                    maskImage: "url('" + _vm.konf.seitenteile.get(index) + "')"
                  }
                },
                [
                  _c("img", {
                    staticClass: "relative h-[450px] m:h-[200px] w-auto",
                    attrs: { src: _vm.konf.seitenteile.get(index), alt: "" }
                  }),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "absolute top-0 left-0 right-0 bottom-0 mix-blend-multiply",
                    style: {
                      backgroundColor: _vm.konf.data.farbAuswahl
                        ? _vm.konf.data.farbAuswahl.color
                        : ""
                    }
                  })
                ]
              )
            ]
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "\n        relative\n        col-start-2 col-span-1\n        row-start-2 row-span-1\n        min-w-0\n        overflow-hidden\n      "
          },
          [
            _c("img", {
              staticClass: "absolute top-0 left-0 right-0 bottom-0 h-full z-10",
              attrs: {
                src: _vm.konf.data.tuergriff
                  ? _vm.konf.data.tuergriff.tuerModul
                  : "",
                alt: ""
              }
            }),
            _vm._v(" "),
            _vm.konf.data.glasart
              ? _c("div", {
                  staticClass: "absolute top-0 left-0 right-0 bottom-0 z-0",
                  style: { backgroundColor: _vm.konf.data.glasart.color }
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "mask h-[450px] m:h-[200px] w-auto",
                style: { maskImage: "url('" + _vm.konf.baseImage + "')" }
              },
              [
                _c("img", {
                  staticClass: "relative h-[450px] m:h-[200px] w-auto",
                  attrs: { src: _vm.konf.baseImage, alt: "" }
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass:
                    "absolute top-0 left-0 right-0 bottom-0 mix-blend-multiply",
                  style: {
                    backgroundColor: _vm.konf.data.farbAuswahl
                      ? _vm.konf.data.farbAuswahl.color
                      : ""
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }