




























































import Vue from 'vue';
export default Vue.extend({
  name: 'infoButton',
  props: {
    text: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    window.addEventListener('popup-close', () => {
      this.show = false;
    });
  },
  methods: {},
});
