































































import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { KonfiguratorService } from '../services/konfigurator.service';
import { TranslationService } from '../services/translation.service';

const SERVICES = VServices({
  konf: KonfiguratorService,
  translation: TranslationService,
});

export default Vue.extend({
  name: 'konfiguratorOverview',
  props: {},
  components: {},
  data() {
    return {
      overviewData: {},
    };
  },
  watch: {
    overviewData: {
      handler: function (newValue) {
        this.sendMessageToParent();
      },
      deep: true,
    },
  },
  mounted() {
    this.overviewData = SERVICES.konf.getOverviewData();
  },
  methods: {
    t(key: string) {
      return SERVICES.translation.t(key);
    },
    sendMessageToParent() {
      this.$emit('messageFromChild', 'test');
    },
  },
});
