/**
 * Webroot URL for folder ./web/
 * including trailing slash
 */
export const BASEURL: string = ( window as any ).BASEURL;
/**
 * Website home of current language
 * including trailing slash
 */
export const SITEURL: string = ( window as any ).SITEURL;
export const API_BASE_URL: string = ( window as any ).API_BASE_URL;

export const KONFIGURATOR_TRANSLATIONS: Record<string, string> = ( window as any ).KONFIGURATOR_TRANSLATIONS;

export const CRAFT_LANGUAGE: string = ( window as any ).CRAFT_LANGUAGE;
