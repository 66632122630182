





































import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import VueHtml2pdf from 'vue-html2pdf';
import { KonfiguratorService } from '../services/konfigurator.service';
import modularDoor from '../components/modularDoor.vue'
import konfiguratorOverview from '../components/konfiguratorOverview.vue'
import axios from "axios";

const SERVICES = VServices({ konf: KonfiguratorService });

export default Vue.extend({
  name: 'PdfGenerator',
  props: {
  },
  data() {
    return {
      options: {
        margin: [0.3, 0, 0.3, 0],
        filename: 'objekt.pdf',
        enableLinks: true,
        html2canvas: {
          scale: 1,
          useCORS: true,
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      konf: SERVICES.konf,
      pdfBlob: ''
    };
  },
  components: {
    VueHtml2pdf,
    modularDoor,
    konfiguratorOverview
  },
  mounted() {

  },
  methods: {
    createPdf() {
      SERVICES.konf.mailAddress = JSON.parse(localStorage.getItem( 'form-data' )).email.value;
      this.$refs.html2Pdf.generatePdf();
    },
    async beforeDownload ({ html2pdf, options, pdfContent }: any) {
      html2pdf().set(options).from(pdfContent).outputPdf('datauristring').then((result: any) => {
        SERVICES.konf.createdPDF = result;
        localStorage.setItem('stored-pdf', result);
      });
    },
    childMessageReceived(arg: any) {
      this.createPdf();
    }
  },
});
