import { VBaseService } from '@libTs/vue-base';
import { BASEURL } from '@libTs/cms.globals';
import axios from "axios";


export class KonfiguratorService extends VBaseService {
  public seitenteile = new Map();
  public baseSeitenteile = new Map();
  public data: any = {};
  public mailboxData: any = {};
  public konfUrl = '';
  public baseImage = '';
  public createdPDF = '';
  public mailAddress = '';
  public sonnerieData: any = {};
  private changeEvent = new CustomEvent( 'seitenteil-changed' );


  public changeSeitenteil( position: string, value: string ) {
    if ( position === 'ohne' ) {
      this.baseSeitenteile.forEach( ( v, k ) => {
        this.seitenteile.set( k, v );
      } );
    } else if ( position === 'both' ) {
      this.seitenteile.set( 'left', value );
      this.seitenteile.set( 'right', value );
    }
    else {
      this.seitenteile.set( position, value );

      this.baseSeitenteile.forEach( ( v, k ) => {
        if ( k !== position ) {
          this.seitenteile.set( k, v );
        }
      } );
    }

    window.dispatchEvent( this.changeEvent );
  }

  public storeData() {
    const data = {
      seitenteile: this.seitenteile,
      baseSeitenteile: this.baseSeitenteile,
      data: this.data,
      baseImage: this.baseImage,
      konfUrl: this.konfUrl,
    };
    localStorage.setItem( 'config-data', JSON.stringify( data, this.replacer ) );
  }

  public storeMailboxData() {
    const data = {
      mailBox: this.mailboxData,
    };
    localStorage.setItem( 'config-mailbox-data', JSON.stringify( data, this.replacer ) );
  }

  public getStoredMailboxData() {
    const storageData = localStorage.getItem( 'config-mailbox-data' );

    if ( storageData ) {
      const data = JSON.parse( storageData, this.reviver );
      this.mailboxData = data.mailBox;
    }
  }

  public getStoredData() {
    const dataLoadedEvent = new CustomEvent( 'data-loaded' );
    const storageData = localStorage.getItem( 'config-data' );

    if ( storageData ) {
      const data = JSON.parse( storageData, this.reviver );

      this.baseSeitenteile = data.baseSeitenteile as Map<any, any>;
      this.seitenteile = data.seitenteile as Map<any, any>;
      this.data = data.data;
      this.baseImage = data.baseImage;
      this.mailboxData = data.mailBox;
      this.konfUrl = data.konfUrl;
    }

    window.dispatchEvent( dataLoadedEvent );
  }

  private replacer( key: any, value: any ) {
    if ( value instanceof Map ) {
      return {
        dataType: 'Map',
        value: Array.from( value.entries() ),
      };
    } else {
      return value;
    }
  }

  private reviver( key: any, value: any ) {
    if ( typeof value === 'object' && value !== null ) {
      if ( value.dataType === 'Map' ) {
        return new Map( value.value );
      }
    }
    return value;
  }

  public changeHauptbild( parsedItem: any ) {
    const changeEvent = new CustomEvent( 'hauptbild-changed' );
    this.mailboxData.hauptbild = parsedItem;
    window.dispatchEvent( changeEvent );
  }

  public getOverviewData(): null | any {
    const configDataString = localStorage.getItem( 'config-data' );
    const configMailboxDataString = localStorage.getItem( 'config-mailbox-data' );
    const configSonnerieDataString = localStorage.getItem( 'config-sonnerie' );
    const formDataString = localStorage.getItem( 'form-data' );
    const formUrl = localStorage.getItem( 'form-url' );

    const configData = configDataString ? JSON.parse( configDataString ) : undefined;
    const formData = formDataString ? JSON.parse( formDataString ) : undefined;
    const mailboxData = configMailboxDataString ? JSON.parse( configMailboxDataString ) : undefined;
    const sonnerieData = configSonnerieDataString ? JSON.parse( configSonnerieDataString ) : undefined;
    const data = [];

    if ( configData ) {
      data.push( { type: "tuer", data: configData.data, url: configData.konfUrl } );
    }
    if ( mailboxData ) {
      data.push( { type: "briefkasten", data: mailboxData.mailBox, url: mailboxData.mailBox.mailboxUrl } );
    }
    if ( sonnerieData ) {
      data.push( { type: "sonnerie", data: sonnerieData, url: sonnerieData.url } );
    }
    if ( formData ) {
      data.push( { type: "personalien", data: formData, url: formUrl } );
    }

    return data;
  }


  public formChanged() {
    const formEvent = new CustomEvent( 'form-changed' );

    window.dispatchEvent( formEvent );
  }

  public action( key: string, link?: string ) {
    switch ( key ) {
      case 'sonnerie-ja':
        this._setSonnerie( 'Ja', 200 );
        break;
      case 'sonnerie-nein':
        this._setSonnerie( 'Nein', 0 );
        break;
      case 'generate-pdf':
        this.generatePdf( this.createdPDF, link ?? '' );
        break;
      case 'download-pdf':
        this.downloadPdf( localStorage.getItem( 'stored-pdf' ), this.data );
        break;
      case 'clear-briefkasten':
        window.localStorage.removeItem( 'config-mailbox-data' );
        break;
      case 'clear':
        window.localStorage.clear();
        break;
      default:
        break;
    }

  }
  private _setSonnerie( labelValue: string, aufpreis: number ) {
    const data = { label: labelValue, url: window.location.href, infos: '', aufpreis: aufpreis };
    this.sonnerieData = data;
    window.localStorage.setItem( 'config-sonnerie', JSON.stringify( data ) );
  }

  public getStoredSonnerie() {
    const data = window.localStorage.getItem( 'config-sonnerie' );

    if ( data ) {
      this.sonnerieData = JSON.parse( data );
    }
  }

  public async generatePdf( createdPDF: any, link: string ) {
    const url = `${BASEURL}actions/hoermann/configurator/mail`;
    const formData = this.parseFormData();

    return axios
      .post(
        url,
        {
          'formData': formData,
          'pdf': createdPDF,
          'email': this.mailAddress
        }
      ).then( res => {
        console.log( res );

        if ( res.status === 200 ) {
          window.location.href = link;
        }
      } ).catch( err => {
        alert( 'Da ist etwas schief gelaufen!' );
        console.error( err );
      } );
  }

  public parseFormData() {
    const data = JSON.parse( window.localStorage.getItem( 'form-data' ) ?? '[]' );
    const form: Record<any, any> = {};
    console.log( data );

    for ( const key in data ) {
      const el = data[ key ];
      form[ key ] = el.value;
    }
    console.log( form );

    return form;
  }

  public downloadPdf( createdPDF: any, data: any ) {
    const name = 'Hörmann-Konfiguration.pdf';

    const link = document.createElement( 'a' );
    link.href = createdPDF;
    link.download = name;
    link.click();
  }
}
