import { VBaseService } from '@libTs/vue-base';


export class FormularService extends VBaseService {
  public formData = {
    anrede: {
      value: '',
      errors: [],
    },
    name: {
      value: '',
      errors: [],
    },
    nachname: {
      value: '',
      errors: [],
    },
    firma: {
      value: '',
      errors: [],
    },
    adresse: {
      value: '',
      errors: [],
    },
    plzOrt: {
      value: '',
      errors: [],
    },
    email: {
      value: '',
      errors: [],
    },
    telefon: {
      value: '',
      errors: [],
    },
  };

  public checkForm( event: Event ) {
    let errors = 0;

    for ( const key in this.formData ) {
      if ( Object.prototype.hasOwnProperty.call( this.formData, key ) ) {
        const element = this.formData[ key ];
        element.errors = [];
        if ( element.value.trim() === '' ) {
          errors++;
          element.errors.push( 'Feld darf nicht leer sein' );
          event.preventDefault();
        }
      }
    }
    console.log( errors );
    console.log( this.formData );

    if ( !errors ) {
      localStorage.setItem( 'form-data', JSON.stringify( this.formData ) );
      localStorage.setItem( 'form-url', window.location.href );
    }
  }

  public getStoredForm() {
    const data = localStorage.getItem( 'form-data' );

    if ( data ) {
      this.formData = JSON.parse( data );
    }
  }
}