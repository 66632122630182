var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { attrs: { autocomplete: "true" } },
    [
      _vm._l(_vm.persFormData, function(items, index) {
        return _c(
          "div",
          { key: index, staticClass: "freeform-row" },
          [
            _c("div", { staticClass: "roboto-bold-f2 ml-2 mt-12 pb-4" }, [
              _c("div", [_vm._v(_vm._s(index))])
            ]),
            _vm._v(" "),
            index === "Personalien"
              ? _c("div", { staticClass: "freeform-column" }, [
                  _c("label", { staticClass: "freeform-label" }, [
                    _vm._v("Anrede")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.formular.formData.anrede.value,
                          expression: "formular.formData.anrede.value"
                        }
                      ],
                      staticClass: "freeform-input",
                      attrs: { name: "anrede" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.formular.formData.anrede,
                            "value",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Herr" } }, [
                        _vm._v("Herr")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Frau" } }, [
                        _vm._v("Frau")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "notSpecified" } }, [
                        _vm._v("keine Angabe")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.formular.formData.anrede.errors.length
                    ? _c(
                        "ul",
                        { staticClass: "errors freeform-errors" },
                        _vm._l(_vm.formular.formData.anrede.errors, function(
                          error,
                          errorIndex
                        ) {
                          return _c("li", { key: error + "-" + errorIndex }, [
                            _vm._v(
                              "\n          " + _vm._s(error) + "\n        "
                            )
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(items, function(item, itemIndex) {
              return _c(
                "div",
                {
                  key: index + "-" + itemIndex,
                  staticClass: "freeform-column"
                },
                [
                  _c("label", { staticClass: "freeform-label" }, [
                    _vm._v(_vm._s(item.label))
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formular.formData[item.handle].value,
                        expression: "formular.formData[item.handle].value"
                      }
                    ],
                    staticClass: "freeform-input",
                    attrs: { type: "text", name: item.handle },
                    domProps: {
                      value: _vm.formular.formData[item.handle].value
                    },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.formular.formData[item.handle],
                          "value",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.formular.formData[item.handle].errors.length
                    ? _c(
                        "ul",
                        { staticClass: "errors freeform-errors" },
                        _vm._l(
                          _vm.formular.formData[item.handle].errors,
                          function(error, errorIndex) {
                            return _c("li", { key: error + "-" + errorIndex }, [
                              _vm._v(
                                "\n          " + _vm._s(error) + "\n        "
                              )
                            ])
                          }
                        ),
                        0
                      )
                    : _vm._e()
                ]
              )
            })
          ],
          2
        )
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ml-2 mb-6 text-f4 text-color3" }, [
        _vm._v("* Pflichtfelder")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }