




























import { VServices } from '@libTs/vue-base';
import Vue from 'vue';
import { KonfiguratorService } from '../services/konfigurator.service';

const SERVICES = VServices({ konf: KonfiguratorService });

export default Vue.extend({
  name: 'modularMailbox',
  props: {
    mailboxData: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      konf: SERVICES.konf,
      baseImage: '',
    };
  },
  mounted() {
    if (this.mailboxData) {
      SERVICES.konf.mailboxData = JSON.parse(this.mailboxData);
    }

    SERVICES.konf.getStoredMailboxData();

    SERVICES.konf.mailboxData.mailboxUrl = window.location.href;
    this.baseImage = SERVICES.konf.mailboxData.hauptbild.hauptbild;
    SERVICES.konf.storeMailboxData();

    window.addEventListener('hauptbild-changed', () => {
      this.baseImage = SERVICES.konf.mailboxData.hauptbild.hauptbild;
      this.$forceUpdate();
    });

    window.addEventListener('form-changed', () => {
      SERVICES.konf.storeMailboxData();
    });
  },
  methods: {},
});
