var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vue-html2pdf",
        {
          ref: "html2Pdf",
          attrs: {
            "show-layout": false,
            "float-layout": true,
            "enable-download": false,
            "preview-modal": false,
            "paginate-elements-by-height": 2400,
            filename: "objekt",
            "pdf-quality": 2,
            "manual-pagination": true,
            "pdf-format": "a4",
            "pdf-orientation": "portrait",
            "pdf-content-width": "800px",
            "html-to-pdf-options": _vm.options
          },
          on: {
            beforeDownload: function($event) {
              return _vm.beforeDownload($event)
            }
          }
        },
        [
          _c(
            "section",
            {
              staticClass: "pdf",
              attrs: { slot: "pdf-content" },
              slot: "pdf-content"
            },
            [
              _c("div", { staticClass: "grid grid-cols-1" }, [
                _c(
                  "div",
                  { staticClass: "pdf-overview" },
                  [
                    _c("div", { staticClass: "roboto-f1 font-bold mb-8" }, [
                      _vm._v("Ihre Konfiguration")
                    ]),
                    _vm._v(" "),
                    _c("konfigurator-overview", {
                      on: { messageFromChild: _vm.childMessageReceived }
                    })
                  ],
                  1
                )
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }