




import Vue from 'vue';
import { VServices } from '@libTs/vue-base';
import { CalcService } from '../services/calc.service';

const SERVICES = VServices({ calc: CalcService });

export default Vue.extend({
  name: 'priceCalculator',
  props: {
    basePrice: {
      required: true,
    },
  },
  components: {},
  data() {
    return {
      price: 0,
      calc: SERVICES.calc,
    };
  },
  mounted() {
    $(() => {
      let basePrice = 0;

      if (this.basePrice) {
        basePrice = this.basePrice;
      } else {
        basePrice = SERVICES.calc.getBasePrice();
      }
      SERVICES.calc.calcPrice(basePrice);

      window.addEventListener('form-changed', () => {
        SERVICES.calc.calcPrice(basePrice);
      });
    });
  },

  methods: {},
});
