// Components
import modularDoor from "./components/modularDoor.vue";
import modularMailbox from "./components/modularMailbox.vue";
import priceCalculator from "./components/priceCalculator.vue";
import pdfGenerator from "./components/pdfGenerator.vue";
import konfiguratorOverview from "./components/konfiguratorOverview.vue";
import userForm from "./components/userForm.vue";
import infoButton from "./components/infoButton.vue";

// Services
import { KonfiguratorService } from "./services/konfigurator.service";
import { FormularService } from "./services/formular.service";
import { TranslationService } from "./services/translation.service";
import { CalcService } from "./services/calc.service";


export const konfComponents = {
  modularDoor,
  modularMailbox,
  priceCalculator,
  pdfGenerator,
  konfiguratorOverview,
  userForm,
  infoButton
};

export const konfServices = [
  KonfiguratorService,
  FormularService,
  TranslationService,
  CalcService
];