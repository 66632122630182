import { VBaseService, VServices } from '@libTs/vue-base';
import { KONFIGURATOR_TRANSLATIONS, SITEURL } from '@libTs/cms.globals';

export class TranslationService extends VBaseService {

  public t( key: string ): string {
    try {
      return KONFIGURATOR_TRANSLATIONS[ key ] ?? key;
    } catch ( error ) {
      console.log( error );
      return key;
    }
  }
}