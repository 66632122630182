import { VServices } from '@libTs/vue-base';
import { VDirective } from '@libTs/vue-base/v.directive';
import { DirectiveBinding } from 'vue/types/options';
import { OverlaysService } from '../services/overlays.service';

const SERVICES = VServices( { overlays: OverlaysService } );

/**
 * v-overlay
 */
export const OverlayDirective: VDirective = [
  'overlay', // translates to attribute "v-overlay" in HTML
  {
    // called only once, when the directive is first bound to the element.
    bind( el: HTMLElement, binding: DirectiveBinding ): void {
      // register overlay handle
      SERVICES.overlays.register( binding.value );
    },

    update( el: HTMLElement, binding: DirectiveBinding ): void {
      if ( SERVICES.overlays.has( binding.value ) ) {
        if ( !el.classList.contains( 'overlay-show' ) ) {
          el.classList.add( 'overlay-show' );
        }
      } else {
        el.classList.remove( 'overlay-show' );
      }
    }
  }
];
